@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Arenq";
  src: url("./fonts/arenq.otf");
}

@font-face {
  font-family: "Mova";
  src: url("./fonts/mova.otf");
}

@font-face {
  font-family: "Beckman";
  src: url("./fonts/beckman.otf");
}

@font-face {
  font-family: "Overcame Bold";
  src: url("./fonts/overcame-bold.woff");
}

@font-face {
  font-family: "Berkshires Wash Regular";
  src: url("./fonts/berkshireswash-regular.ttf");
}

@font-face {
  font-family: "Overcame Outline";
  src: url("./fonts/overcame-outline.woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  header, section, footer {
    @apply px-4 md:px-16 lg:px-20 xl:px-40 2xl:px-60 !important
  }
}

@media (prefers-color-scheme: dark) {
  .favicon-smiley { fill: #FFFF00 }
  .notification-background{ fill: #FFC300 }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Black */
.carousel-black > button.slick-arrow.slick-prev.slick-prev:before,
.carousel-black > button.slick-arrow.slick-next.slick-next:before {
  color: white;
}

.carousel-dots-black li button:before {
  color: white !important;
  font-size: 1rem;
}
.carousel-dots.black.slick-dots li button:after {
  color: white !important;
  font-size: 1rem;
}

.carousel-dots-black {
  width: fit-content;
  position: initial;
  margin: auto;
  display: flex;
  border-radius: 0.5rem;
}
.carousel-dots-light {
  width: fit-content;
  position: initial;
  margin: auto;
  display: flex;
  border-radius: 0.5rem;
}

/* Light */
.carousel-light > button.slick-arrow.slick-prev.slick-prev:before,
.carousel-light > button.slick-arrow.slick-next.slick-next:before {
  color: black;
}

.carousel-dots-light.slick-dots li button:before {
  color: black !important;
  font-size: 1rem;
}
.carousel-dots-light.slick-dots li button:after {
  color: black !important;
  font-size: 1rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-track
{
    display: flex !important;
    margin-bottom: 1rem;
}

.slick-slide
{
    height: inherit !important;
}

.slick-slide > div {
  height:  100% !important;
}

.client-highlight-container {
  background-color: #f0f4f8;
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  z-index: 1;
}

.client-highlight-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}

.client-highlight-description {
  font-size: 16px;
  color: #616060;
}

.client-highlight-card {
  background-color: #f0f4f8;
  background: linear-gradient(to bottom, #f0f4f8 50%, transparent 50%);
}

.client-highlight-image-description-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.client-highlight-image {
  flex: 1;
  position: relative;
  max-width: 40%;
  overflow: hidden;
}

.client-highlight-image img {
  max-width: 100%;
  height: 450px;
}

.client-highlight-image-description {
  flex: 1;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 20px;
  box-sizing: border-box;
}

.client-highlight-image-description-scrollable{
  white-space: normal;
  word-wrap: break-word;
}

.industry-container {
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.industry-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}

.industry-description {
  font-size: 16px;
}

.industry-image-description-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.industry-image {
  flex: 1;
  position: relative;
  max-width: 50%;
  overflow: hidden;
}

.industry-image img {
  max-width: 100%;
  height: 450px;
}

.industry-image-description {
  flex: 1;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 20px;
  box-sizing: border-box;
}

.industry-image-description-scrollable{
  white-space: normal;
  word-wrap: break-word;
}